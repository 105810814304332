import React from "react";

const MainLineIpoIcon = () => {
  return (
    <span className="menu-icon">
      <span className="svg-icon svg-icon-muted svg-icon-2x">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.8 8.79999L13 13.6L9.7 10.3C9.3 9.89999 8.7 9.89999 8.3 10.3L2.3 16.3C1.9 16.7 1.9 17.3 2.3 17.7C2.5 17.9 2.7 18 3 18C3.3 18 3.5 17.9 3.7 17.7L9 12.4L12.3 15.7C12.7 16.1 13.3 16.1 13.7 15.7L19.2 10.2L17.8 8.79999Z"
            fill="currentColor"
          />
          <path
            opacity="0.3"
            d="M22 13.1V7C22 6.4 21.6 6 21 6H14.9L22 13.1Z"
            fill="currentColor"
          />
        </svg>
      </span>
    </span>
  );
};

export default MainLineIpoIcon;
